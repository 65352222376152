import { AsyncPipe, DOCUMENT, Location, NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Inject,
  input,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DomHelperService } from '../../services/dom/dom-helper.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IconButtonComponent } from '../button/icon-button/icon-button.component';
import { ArticleScaledDiscountComponent } from '../article-scaled-discount/article-scaled-discount.component';
import { QuantityInputComponent } from '../quantity-input/quantity-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArticlePriceComponent } from '../article-price/article-price.component';
import { ImageComponent } from '../image/image.component';
import { RouterLink } from '@angular/router';
import { ArticleFavoriteComponent } from '../article-favorite/article-favorite.component';
import { ArticleCompareItemComponent } from '../article-compare/article-compare-item/article-compare-item.component';
import { ArticlePromoPriceComponent } from '../article-promo-price/article-promo-price.component';
import { ArticleAvailabilityComponent } from '../article-availability/article-availability.component';
import { ArticleService } from '../../services/catalog/article.service';
import { GlobalStore } from '../../store/global.store';
import { createPendingObserverResult } from '@ngneat/query';
import { SuedoArticle } from '../../interfaces/suedo-article.interface';
import { BreadcrumbHelperService } from '../../services/breadcrumb/breadcrumb-helper.service';
import { UrlPathPipe } from '../../pipes/url-path.pipe';
import { ArticleMainImagePipe } from '../../pipes/article-main-image.pipe';
import { derivedAsync } from 'ngxtension/derived-async';
import { isPlatformBrowserProvider } from '../../providers/is-platform-browser.provider';

@UntilDestroy()
@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    NgIf,
    ArticleAvailabilityComponent,
    ArticlePromoPriceComponent,
    ArticleCompareItemComponent,
    ArticleFavoriteComponent,
    RouterLink,
    ImageComponent,
    ArticlePriceComponent,
    ReactiveFormsModule,
    FormsModule,
    QuantityInputComponent,
    ArticleScaledDiscountComponent,
    IconButtonComponent,
    NgClass,
    NgFor,
    NgxSkeletonLoaderModule,
    AsyncPipe,
    UrlPathPipe,
    ArticleMainImagePipe,
  ],
})
export class ArticleItemComponent {
  inputArticle = input<SuedoArticle>();
  articleId = input<string>();
  @Input() quantity = 1;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuantityChange = new EventEmitter();
  isBrowser = isPlatformBrowserProvider();
  articleService = inject(ArticleService);
  store = inject(GlobalStore);

  article = derivedAsync(() => {
    if (this.inputArticle()) {
      return of(this.inputArticle());
    }
    return this.articleService.getArticle(this.articleId()!).result$.pipe(
      filter((result) => result.isSuccess),
      map((result) => {
        if (result.error) {
          throw result.error;
        }
        return result.data as SuedoArticle;
      }),
    );
  });

  replacedArticles = derivedAsync(() => {
    if (this.article()) {
      return this.articleService.getArticleReplacedBy(this.article()!.sArticleID).result$;
    }
    return of(createPendingObserverResult<SuedoArticle[]>());
  }, { initialValue: createPendingObserverResult<SuedoArticle[]>() });

  private currentBoxHeight: number = 0;
  priceIsZero: boolean = false;

  @HostListener('mouseenter')
  onMouseEnter = () => {
    const element = this.elementRef.nativeElement;
    const box = element.querySelector('.js-box');
    const cartEl = box.querySelector('.js-box-hover');

    if (parseInt(box.getAttribute('data-minheight')!) == this.currentBoxHeight && window.innerWidth > 767) {
      const getAdditionalHeight = parseInt(box.getAttribute('data-hover-height')!);
      const jsBoxHeightWithCurrent = box.querySelector('.js-box-height') as HTMLElement | null;
      jsBoxHeightWithCurrent!.style.height = this.currentBoxHeight + getAdditionalHeight + 'px';
      box.classList.remove('z-10');
      box.classList.add('z-40');
      cartEl?.classList.remove('md:hidden');
    }
  };

  @HostListener('mouseleave')
  onMouseLeave = () => {
    const element = this.elementRef.nativeElement;
    const box = element.querySelector('.js-box');
    const cartEl = box.querySelector('.js-box-hover');

    if (parseInt(box.getAttribute('data-minheight')!) == this.currentBoxHeight && window.innerWidth > 767) {
      const jsBoxHeight = box.querySelector('.js-box-height') as HTMLElement | null;
      jsBoxHeight!.style.height = '';
      box.classList.add('z-10');
      box.classList.remove('z-40');
      cartEl?.classList.add('md:hidden');
    }
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private breadcrumbHelperService: BreadcrumbHelperService,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    effect(() => {
      if (this.article() && this.replacedArticles().isSuccess) {
        this.quantity = this.article()!.decQuantityPackage > 0 ? this.article()!.decQuantityPackage : 1;
        this.showCartBtnOnHover();
      }
    });
  }

  showCartBtnOnHover() {
    if (!this.isBrowser) {
      return;
    }

    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      const box = element.querySelector('.js-box');
      const innerBoxEl = box.querySelector('.js-box-height');
      const cartEl = box.querySelector('.js-box-hover');
      this.currentBoxHeight = parseInt(getComputedStyle(innerBoxEl!).height) + 4;

      if (DomHelperService.is_touch_enabled()) {
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
          cartEl.classList.add('js-touch');
          cartEl.classList.remove('md:hidden');
          box.style.minHeight = '';
          box.querySelector('.js-box-height')!.classList.remove('md:absolute');
          box.querySelector('.js-box-height')!.classList.remove('md:block');
          box.classList.remove('min-h-0');
          box.classList.remove('md:min-h-350px');
        }
      } else {
        DomHelperService.checkBoxMinHeight(box, this.currentBoxHeight);
      }
    }, 0);
  }

  quantityChanged($event: any) {
    this.quantity = $event;
  }


  addToCart(article: SuedoArticle) {
    if (!article || this.quantity <= 0) {
      return;
    }

    this.store.createCartItem({
      sArticleID: article.sArticleID.toString(),
      decQuantity: this.quantity,
      sQuantityUnit: article.sQuantityUnitSales,
      oArticle: article
    });
  }

  public setPreviousUrl() {
    this.breadcrumbHelperService.setPreviousUrl(this.location.path());
  }
}
