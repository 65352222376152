export class DomHelperService {
  public static delay(elem: any, callback: (...args: any[]) => void): void {
    let timeout: NodeJS.Timeout | null = null;
    elem.onmouseover = function () {
      // Set timeout to be a timer which will invoke callback after 1s
      timeout = setTimeout(callback, 400);
    };

    elem.onmouseout = function () {
      // Clear any timers set to timeout
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }

  public static showContent(el: Element | null): void {
    if (!el) {
      return;
    }

    el.classList.remove('hidden');
    el.classList.add('block');
  }

  public static hideContent(el: Element | null): void {
    if (!el) {
      return;
    }

    el.classList.add('hidden');
    el.classList.remove('block');
  }

  public static is_touch_enabled() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  public static checkBoxMinHeight(el: HTMLElement, currentHeight: number) {
    if (parseInt(el.getAttribute('data-minheight')!) != currentHeight && window.innerWidth > 767) {
      el.style.minHeight = currentHeight + 'px';
      el.setAttribute('data-minheight', String(currentHeight));
    }
  }

  public static fallback100vh(domDocument: Document) {
    if (domDocument.querySelectorAll('.h-screen').length > 0) {
      [].forEach.call(domDocument.querySelectorAll('.h-screen'), function (hscreenEl: HTMLElement) {
        if (DomHelperService.is_touch_enabled()) {
          if (hscreenEl.classList.contains('js-screen-mobile') && window.innerWidth > 767) {
            hscreenEl.style.height = '';
          } else {
            hscreenEl.style.height = window.innerHeight + 'px';
          }
        } else {
          hscreenEl.style.height = '';
        }
      });

      [].forEach.call(domDocument.querySelectorAll('.min-h-screen'), function (minhscreenEl: HTMLElement) {
        if (DomHelperService.is_touch_enabled()) {
          if (minhscreenEl.classList.contains('js-screen-mobile') && window.innerWidth > 767) {
            minhscreenEl.style.minHeight = '';
          } else {
            minhscreenEl.style.minHeight = window.innerHeight + 'px';
          }
        } else {
          minhscreenEl.style.minHeight = '';
        }
      });
    }
  }

  public static removeMobileNavHeight(domDocument: Document) {
    //remove md:min-h-0 class for mobile nav if you are on a touch device which is bigger then 767 pixel
    if (window.innerWidth > 767 && window.innerWidth < 1280) {
      domDocument.querySelector('.js-mobile-nav-height')?.classList.remove('md:min-h-0');
    }
  }
}
