<ng-container *transloco="let t; scope: 'shared'">
  <div
    *ngIf="article() as article; else articleLoading"
    class="js-box group h-full md:min-h-350px relative z-10"
    data-minheight="364"
    data-hover-height="52"
    style="min-height: 364px"
  >
    <div
      *ngIf="replacedArticles().data as replacedArticles; else articleLoading"
      class="js-box-height transition duration-200 relative md:absolute h-full overflow-hidden bg-white border border-gray-500 w-full shadow-sm rounded-md flex justify-between flex-col md:block group-hover:shadow-xl"
      style=""
    >
      <div class="flex p-4 pb-2 absolute left-0">
        @defer (on viewport) {
          <app-article-availability [article]="article" [showLabel]="false"
                                    [showPopoverTop]="false"></app-article-availability>
          <div *ngIf="store.isLoggedIn() && !store.user()?.oCustomer?.bPrivateCustomer">
            <app-article-promo-price [article]="article"></app-article-promo-price>
          </div>
        } @placeholder {
          <div></div>
        }
      </div>
      <div class="flex p-4 pb-2 absolute right-0">
        <app-article-compare-item
          [article]="article"
          [showAsButton]="true"
          class="flex md:flex-col items-center md:space-y-4 w-auto md:w-8 ml-4 md:ml-0 mr-4 sm:mr-0"
        ></app-article-compare-item>
        <app-article-favorite [article]="article" [showAsButton]="true"></app-article-favorite>
      </div>

      <a
        [routerLink]="'/article/' + article?.sArticleID | urlPath"
        (click)="setPreviousUrl()"
        class="js-link flex flex-col"
      >
        <div class="bg-white flex items-center justify-center py-4">
          <div class="sm:h-48 w-7/12 md:w-full flex">
            <app-image
              class="sm:w-auto sm:h-auto md:h-full sm:max-h-full mx-auto self-center"
              classes="sm:w-auto sm:h-auto md:h-full sm:max-h-full mx-auto self-center"
              [image]="article! | articleMainImage"
            ></app-image>
          </div>
        </div>

        <div class="flex-1 p-4 pb-2 space-y-2 flex flex-col">
          <h3
            class="min-h-50px leading-snug max-w-10/12 md:max-w-full text-lg font-bold line-clamp-none md:line-clamp-2 transition duration-200 group-hover:text-primary"
          >
            {{ article?.sName }}
          </h3>
          <p
            class="leading-snug max-w-10/12 md:max-w-full">{{ t("shared.article-item.item-id", { itemId: article?.sUniformNumber }) }}</p>
          <div *ngIf="article.shtStatus !== 2 && replacedArticles.length === 0" class="flex-1 flex justify-end">
            <p class="text-base font-bold text-primary self-end -mt-3">
              @defer (on viewport) {
                <app-article-price [article]="article" (zeroPrice)="priceIsZero = $event"
                                   [showPromotion]="true"></app-article-price>
              } @placeholder {
                <div></div>
              }

            </p>
          </div>
        </div>
      </a>
      <form *ngIf="!priceIsZero" class="js-box-hover pb-4 px-4 flex space-x-4 justify-between md:hidden">
        <ng-container *ngIf="article.shtStatus !== 2 && replacedArticles.length === 0; else replaced">
          <div class="md:-mt-4 w-5/12">
            <app-quantity-input (onQuantityChange)="quantityChanged($event)" [article]="article"
                                [quantity]="quantity"></app-quantity-input>
          </div>
          <div class="relative group flex">
            <app-article-scaled-discount [article]="article"></app-article-scaled-discount>
          </div>
          <div class="w-5/12">
            <app-icon-button
              [text]="t('shared.article-item.buy')"
              [background]="'primary'"
              [textColor]="'white'"
              [hover]="'secondary'"
              [iconPosition]="'right'"
              (click)="addToCart(article)"
              [icon]="store.isCartLoading() ? 'loading' : 'cart'"
              [ngClass]="store.isCartLoading() ? 'pointer-events-none' : ''"
            ></app-icon-button>
          </div>
        </ng-container>
        <ng-template #replaced>
          <ng-container *ngIf="replacedArticles.length > 0; else lockedWithoutReplacement">
            <div>
              <span>
                {{ t("shared.article-item.replaced-by") }}
              </span>
              <a
                *ngFor="let replacedArticle of replacedArticles"
                [routerLink]="'/article/' + replacedArticle.sArticleID | urlPath"
                class="font-bold"
              >
                {{ replacedArticle.sUniformNumber }}
              </a>
            </div>
          </ng-container>
          <ng-template #lockedWithoutReplacement>
            <span>
              {{ t("shared.article-item.locked") }}
            </span>
          </ng-template>
        </ng-template>
      </form>
    </div>
  </div>

  <ng-template #articleLoading>
    <div class="js-box group h-full md:min-h-350px relative z-10" data-minheight="364" data-hover-height="52"
         style="min-height: 364px">
      <div
        class="js-box-height transition duration-200 relative md:absolute h-full overflow-hidden bg-white border border-gray-500 w-full shadow-sm rounded-md flex justify-between flex-col md:block group-hover:shadow-xl"
        style=""
      >
        <a class="js-link flex flex-col">
          <div class="bg-white flex items-center justify-center py-4">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '192px',
                'border-radius': 0,
                width: '192px',
                background: '#F5F5F5'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="flex-1 p-4 pb-2 space-y-2 flex flex-col">
            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '60px',
                'border-radius': 0,
                background: '#F5F5F5'
              }"
            ></ngx-skeleton-loader>

            <ngx-skeleton-loader
              count="1"
              appearance="line"
              [theme]="{
                height: '30px',
                'border-radius': 0,
                width: '80px',
                float: 'right',
                background: '#F5F5F5'
              }"
            ></ngx-skeleton-loader>
          </div>
        </a>
      </div>
    </div>
  </ng-template>
</ng-container>
